exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-done-js": () => import("./../../../src/pages/contact_done.js" /* webpackChunkName: "component---src-pages-contact-done-js" */),
  "component---src-pages-contact-ng-js": () => import("./../../../src/pages/contact_ng.js" /* webpackChunkName: "component---src-pages-contact-ng-js" */),
  "component---src-pages-estimate-js": () => import("./../../../src/pages/estimate.js" /* webpackChunkName: "component---src-pages-estimate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-skill-template-js": () => import("./../../../src/templates/skill-template.js" /* webpackChunkName: "component---src-templates-skill-template-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

